export default function (type) {
  switch (type) {
    // ==== 路珠
    // 号码
    case 'number_road_bead':
      return 'lz/number'
    // 大小
    case 'big_small_road_bead':
      return 'lz/big_small'
    // 单双
    case 'single_double_road_bead':
      return 'lz/single_double'
    // 龙虎
    case 'loong_tiger_road_bead':
      return 'lz/loong_tiger'
    // 组合
    case 'combination_road_bead':
      return 'lz/combination'
    // 前后
    case 'before_after_road_bead':
      return 'lz/before_after'
    // 生肖路珠
    case 'zodiac_road_bead':
      return 'lz/zodiac'
    // 阴阳路珠
    case 'yin_yang_road_bead':
      return 'lz/yin_yang'
    // 禽兽路珠
    case 'animals_road_bead':
      return 'lz/animals'
    // 五行路珠
    case 'five_shapes_road_bead':
      return 'lz/five_shapes'
    // 八卦路珠
    case 'eight_trigrams_road_bead':
      return 'lz/eight_trigrams'
    // 花色
    case 'decor_road_bead':
      return 'lz/decor'
    // 中发白
    case 'centre_hair_white_road_bead':
      return ''
    // 方位
    case 'around_road_bead':
      return ''
    // 前中后
    case 'before_center_after_road_bead':
      return ''
    // 总和
    case 'sum_road_bead':
      return 'lz/sum'
    // 形态
    case 'pattern_road_bead':
      return 'lz/pattern'
    case 'tail_road_bead':
      return 'lz/tail'
    case 'bose_road_bead':
      return 'lz/bose'
    // ==== 走势
    // 号码走势
    case 'number_trend':
      return 'trend/number'
    // 大小走势
    case 'big_small_trend':
      return 'trend/size'
    // 单双走势
    case 'single_double_trend':
      return 'trend/single-double'
    // 跟随走势
    case 'follow_trend':
      return 'trend/following'
    // 龙虎走势
    case 'loong_tiger_trend':
      return 'trend/dragon-tiger'
    // 前后走势
    case 'before_after_trend':
      return 'trend/before-after'
    // 位置走势
    case 'position_trend':
      return 'trend/location'
    // 组合走势
    case 'combination_trend':
      return 'trend/combination'
    // 生肖走势
    case 'zodiac_trend':
      return 'trend/zodiac/zodiac'
    // 阴阳走势
    case 'yin_yang_trend':
      return 'trend/zodiac/yin_yang'
    // 禽兽走势
    case 'animals_trend':
      return 'trend/zodiac/animals'
    // 五行走势
    case 'five_shapes_trend':
      return 'trend/zodiac/five_shapes'
    // 八卦走势
    case 'eight_trigrams_trend':
      return 'trend/zodiac/eight_trigrams'
    // 生肖前后走势
    case 'qian_hou_trend':
      return 'trend/zodiac/before_after'
    // 中发白走势
    case 'centre_hair_white_trend':
      return ''
    // 方位走势
    case 'around_trend':
      return ''
    // 前中后走势
    case 'before_center_after_trend':
      return ''
    // 总和走势
    case 'sum_trend':
      return 'trend/sum'
    // 形态走势
    case 'pattern_trend':
      return 'trend/pattern'
    // 花色走势
    case 'decor_trend':
      return 'trend/decor'
    // 尾数走势
    case 'tail_trend':
      return 'trend/tail'
    // 波色走势
    case 'bose_trend':
      return 'trend/bose'
    // ==== 数据
    // 今日号码
    case 'today_number_data':
      return 'data/today'
    // 冷热分析
    case 'hot_cold_data':
      return 'data/analyse'
    // 历史统计
    case 'history_statistics_data':
      return 'data/statistics'
    // 每日长龙
    case 'long_dragon_data':
      return 'data/long'
    // 特码分析
    case 'special_code_data':
      return 'data/tema'
    // 历史开奖
    case 'history_open_data':
      return 'data/history'
    // 遗漏统计
    case 'missing_data':
      return 'data/omit'
    case 'map_statistics_data':
      return 'data/map'
    // ==== 推荐
    // 公式推介
    case 'formula_recommend':
      return 'recommend/formula'
    // 杀号推介
    case 'kill_recommend':
      return 'recommend/kill'
    // 计划推介
    case 'plan_recommend':
      return 'recommend/plan'
    default:
      return ''
  }
}
