import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay, Navigation } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// https://swiperjs.com/api/#custom-build
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation])
export const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

// export class Swiper

// export default {
//   Swiper,
//   SwiperSlide
// }
